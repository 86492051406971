export default Object.freeze({
  identifier: 'identifier',
  // Policies - Time Windows
  returnWindowEnabled: 'settings.policies.return_window_enabled',
  returnWindowDays: 'settings.policies.return_window_days',
  refundTimingEstimate: 'settings.policies.refund_timing_estimate',
  dropOffWindowDays: 'settings.policies.drop_off_window_days',
  holidayReturnWindows: 'settings.policies.holiday_return_windows',
  // Policies - Return Reasons
  returnReasons: 'settings.policies.return_reasons',
  // Settings - Returns
  dropOffsIsEnabled: 'settings.policies.return_methods.drop_offs_is_enabled',
  dropOffsFlatFeeAmount: 'settings.policies.return_methods.drop_offs_flat_fee_amount',
  dropOffsDistanceThreshold: 'settings.policies.return_methods.drop_offs_distance_threshold',
  homePickupIsEnabled: 'settings.policies.return_methods.home_pickup_is_enabled',
  homePickupFlatFeeAmount: 'settings.policies.return_methods.home_pickup_flat_fee_amount',
  mailBackFlatFeeAmount: 'settings.policies.return_methods.mail_back_flat_fee_amount',
  mailBackHasOnlyMethodOfferedFeeEnabled: 'settings.policies.return_methods.mail_back_has_only_method_offered_fee_enabled',
  mailBackOnlyMethodOfferedFee: 'settings.policies.return_methods.mail_back_only_method_offered_fee',
  mailBackPackingSlipExtraField: 'settings.policies.packing_slip.extra_item_identifier',
  mailBackPackingSlipExtraFieldTitle: 'settings.policies.packing_slip.extra_item_identifier_title',
  mailBackAddVariantsToTitlesEnabled: 'settings.policies.packing_slip.add_variants_to_titles',
  insufficientRefundEnabled: 'settings.policies.feature_flags.suppress_pickups_for_insufficient_refund_enabled',
  supportedSortClassifications: 'settings.policies.supported_sort_classifications',
  giftReturnsAllowedEnabled: 'settings.config.giftReturnsAllowed',
  // Settings - Account Info
  retailerName: 'settings.policies.retailer_info.name',
  retailerPhone: 'settings.config.customerSupportContact',
  retailerEmail: 'settings.policies.retailer_info.customer_support_email',
  retailerFromEmail: 'settings.policies.retailer_info.from_email',
  portalUrlPrefix: 'settings.config.urlPrefix',
  // Settings - Exchanges
  instantExchangesEnabled: 'settings.policies.exchanges.instant.enabled',
  instantGiftCardsEnabled: 'settings.policies.exchanges.instant_gift_card.enabled',
  standardExchangesEnabled: 'settings.policies.exchanges.standard.enabled',
  // Shipping Info
  shippingAddressName: 'settings.policies.ship_to_address.name',
  shippingAddressCompany: 'settings.policies.ship_to_address.company',
  shippingAddressStreet1: 'settings.policies.ship_to_address.street1',
  shippingAddressStreet2: 'settings.policies.ship_to_address.street2',
  shippingAddressCity: 'settings.policies.ship_to_address.city',
  shippingAddressState: 'settings.policies.ship_to_address.state',
  shippingAddressZipcode: 'settings.policies.ship_to_address.zip',
  shippingMailbackShippingAccounts: 'settings.policies.return_methods.mailback_shipping_accounts',
  // Customize - Logo Assets
  favicon: 'settings.styles.logo_assets.favicon',
  mainLogo: 'settings.styles.logo_assets.main_logo',
  // Customize - Fonts
  brandFontAssets: 'settings.styles.fonts.brand_font_assets',
  mainHeaderBrandFontFamily: 'settings.styles.fonts.main_header_brand_font_family',
  mainHeaderWebFontFamily: 'settings.styles.fonts.main_header_web_font_family',
  subHeaderBrandFontFamily: 'settings.styles.fonts.sub_header_brand_font_family',
  subHeaderWebFontFamily: 'settings.styles.fonts.sub_header_web_font_family',
  headerColor: 'settings.styles.fonts.header_color',
  bodyBrandFontFamily: 'settings.styles.fonts.body_brand_font_family',
  bodyWebFontFamily: 'settings.styles.fonts.body_web_font_family',
  bodyColor: 'settings.styles.fonts.body_color',
  // Customize - Order Lookup
  orderLookupImage: 'settings.styles.order_lookup_image',
  orderLookupHeader: 'settings.strings.ORDER_LOOKUP.RETURN_AND_TRACK_ORDERS',
  orderLookupInputLabel: 'settings.strings.ORDER_LOOKUP.ENTER_ORDER_ID',
  orderLookupInputPlaceholder: 'settings.strings.ORDER_LOOKUP.ENTER_ORDER_ID_PLACEHOLDER',
  orderLookupButtonText: 'settings.strings.ORDER_LOOKUP.NEXT',
  // Customize - Order Id help modal
  orderIdHelpModalEnabled: 'settings.config.orderIdHelpEnabled',
  orderIdHelpModalImage1: 'settings.styles.order_id_help_modal.image1',
  orderIdHelpModalImage2: 'settings.styles.order_id_help_modal.image2',
  orderIdHelpModalImage3: 'settings.styles.order_id_help_modal.image3',
  orderIdHelpModalHelpQuestion: 'settings.strings.ORDER_ID_HELP_MODAL.HELP_QUESTION',
  orderIdHelpModalHeader: 'settings.strings.ORDER_ID_HELP_MODAL.HEADER',
  orderIdHelpModalMessage: 'settings.strings.ORDER_ID_HELP_MODAL.MESSAGE',
  orderIdHelpModalMessageOptional1: 'settings.strings.ORDER_ID_HELP_MODAL.MESSAGE_OPTIONAL_1',
  orderIdHelpModalMessageOptional2: 'settings.strings.ORDER_ID_HELP_MODAL.MESSAGE_OPTIONAL_2',
  // Customize - Buttons
  buttonRoundedEnabled: 'settings.styles.buttons.button_rounded_enabled',
  buttonRadius: 'settings.styles.buttons.button_radius',
  buttonTextUppercaseEnabled: 'settings.styles.buttons.button_text_uppercase_enabled',
  buttonPrimaryActiveBackgroundColor: 'settings.styles.buttons.button_primary_active_background_color',
  buttonPrimaryActiveTextColor: 'settings.styles.buttons.button_primary_active_text_color',
  buttonPrimaryHoverBackgroundColor: 'settings.styles.buttons.button_primary_hover_background_color',
  buttonPrimaryHoverTextColor: 'settings.styles.buttons.button_primary_hover_text_color',
  buttonPrimaryDisabledBackgroundColor: 'settings.styles.buttons.button_primary_disabled_background_color',
  buttonPrimaryDisabledTextColor: 'settings.styles.buttons.button_primary_disabled_text_color',
  buttonSecondaryActiveBackgroundColor: 'settings.styles.buttons.button_secondary_active_background_color',
  buttonSecondaryActiveBorderColor: 'settings.styles.buttons.button_secondary_active_border_color',
  buttonSecondaryActiveTextColor: 'settings.styles.buttons.button_secondary_active_text_color',
  buttonSecondaryHoverBackgroundColor: 'settings.styles.buttons.button_secondary_hover_background_color',
  buttonSecondaryHoverBorderColor: 'settings.styles.buttons.button_secondary_hover_border_color',
  buttonSecondaryHoverTextColor: 'settings.styles.buttons.button_secondary_hover_text_color',
  buttonSecondaryDisabledBackgroundColor: 'settings.styles.buttons.button_secondary_disabled_background_color',
  buttonSecondaryDisabledBorderColor: 'settings.styles.buttons.button_secondary_disabled_border_color',
  buttonSecondaryDisabledTextColor: 'settings.styles.buttons.button_secondary_disabled_text_color',
  buttonTertiaryActiveTextColor: 'settings.styles.buttons.button_tertiary_active_text_color',
  buttonTertiaryHoverTextColor: 'settings.styles.buttons.button_tertiary_hover_text_color',
  buttonTertiaryDisabledTextColor: 'settings.styles.buttons.button_tertiary_disabled_text_color',
  // Customize  - Return Summary
  returnSummaryShippingFeeLabel: 'settings.strings.RETURN_REVIEW.SHIPPING_FEE',
  // Customize - Sitewide Info
  siteWideEnabled: 'settings.config.siteWideMessageEnabled',
  siteWideTextColor: 'settings.styles.site_wide_info.text_color',
  siteWideBackgroundColor: 'settings.styles.site_wide_info.background_color',
  siteWideMessage: 'settings.config.importantMessage',
  // Customize - Refund
  instantGiftCardHeadline: 'settings.strings.EXCHANGE_REFUND.INSTANT_GIFT_CARD',
  instantGiftCardSubcopy: 'settings.strings.EXCHANGE_REFUND.INSTANT_GIFT_CARD_MESSAGE',
  optionalGiftCardDisclaimer: 'settings.strings.EXCHANGE_REFUND.INSTANT_GIFT_CARD_DISCLAIMER',
  // Customize - Ineligible Returns Modal
  returnWindowExpirationModalHeader: 'settings.strings.ORDER_DISPLAY.ITEM_RETURN_POLICY_TITLE',
  returnWindowExpirationModalCopy: 'settings.strings.ORDER_DISPLAY.ITEM_RETURN_POLICY_DESC',
  finalSaleModalHeader: 'settings.strings.ORDER_DISPLAY.FINAL_SALE_MODAL_HEADER',
  finalSaleModalCopy: 'settings.strings.ORDER_DISPLAY.FINAL_SALE_MODAL_BODY',
  thirdPartyModalHeader: 'settings.strings.ORDER_DISPLAY.THIRD_PARTY_MODAL_HEADER',
  thirdPartyModalCopy: 'settings.strings.ORDER_DISPLAY.THIRD_PARTY_MODAL_BODY',
  // Customize - How it works modal
  howItWorksModalHeader: 'settings.strings.HOW_IT_WORKS.HOW_IT_WORKS',
  howItWorksModalBody: 'settings.strings.HOW_IT_WORKS.HOW_IT_WORKS_BODY',
  // Developers
  policiesJson: 'settings.policies',
  stringsJson: 'settings.strings',
  stylesJson: 'settings.styles',
  configJson: 'settings.config',
})
