import { object, string } from 'yup'
import { HEX_COLOR_REGEX } from '@/constants'

export default object({
  text_color: string().label('Text color').when(
    '$values.settings.config.siteWideMessageEnabled',
    ([siteWideMessageEnabled], schema) => {
      return siteWideMessageEnabled
        ? schema.matches(HEX_COLOR_REGEX, 'Enter a valid hexadecimal code (example: #385ADC)').required()
        : schema.nullable()
    }
  ),
  background_color: string().label('Background color').when(
    '$values.settings.config.siteWideMessageEnabled',
    ([siteWideMessageEnabled], schema) => {
      return siteWideMessageEnabled ? schema.required() : schema.nullable()
    }
  )
})
