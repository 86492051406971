<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import HomePreview from '@/components/customize/HomePreview.vue'
import OrderIdHelpModalPreview from '@/components/customize/OrderIdHelpModalPreview.vue'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldTextarea from '@/components/FormFieldTextarea.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldImage from '@/components/FormFieldImage.vue'
import { getImagesLocation } from '@/utils/portal'
import { computed } from 'vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())

const isOrderIdHelpDisabled = computed(() => {
  return isLoading.value || !selectedPortal.value.fields.orderIdHelpModalEnabled.inputValue
})
function onChangeOrderIdHelpEnabled() {
  const { fields } = selectedPortal.value
  fields.orderIdHelpModalHelpQuestion.inputValue = null
  fields.orderIdHelpModalHeader.inputValue = null
  fields.orderIdHelpModalMessage.inputValue = null
}
</script>
<template>
  <div class="row">
    <div class="col-4">
      <div>
        <h3>Order ID Help Modal</h3>
      </div>
      <div>
        Provide instructions about how to find the order ID. The instructions will appear as a help link/modal on the Order Lookup page.
      </div>
      <br>
      <div class="form-group">
        <FormFieldCheckbox
          class="form-check-input"
          role="switch"
          :fieldModel="selectedPortal.fields.orderIdHelpModalEnabled"
          :disabled="isLoading"
          @change="onChangeOrderIdHelpEnabled"
        />
        <label class="form-label ms-2">Display an Order ID Help Modal</label>
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">
          Order ID help link
          <span v-if="selectedPortal.fields.orderIdHelpModalEnabled.inputValue">*</span>
        </label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.orderIdHelpModalHelpQuestion"
          :disabled="isOrderIdHelpDisabled"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.orderIdHelpModalHelpQuestion" />
      </div>
    </div>
    <div class="col-8">
      <HomePreview />
    </div>
  </div>
  <hr class="border border-dark my-5">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label class="form-label">
          Header
          <span v-if="selectedPortal.fields.orderIdHelpModalEnabled.inputValue">*</span>
        </label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.orderIdHelpModalHeader"
          :disabled="isOrderIdHelpDisabled"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.orderIdHelpModalHeader" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">
          Body Copy
          <span v-if="selectedPortal.fields.orderIdHelpModalEnabled.inputValue">*</span>
        </label>
        <FormFieldTextarea
          class="form-control"
          :disabled="isOrderIdHelpDisabled"
          :fieldModel="selectedPortal.fields.orderIdHelpModalMessage"
          rows="5"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.orderIdHelpModalMessage" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">Image (Optional)</label>
        <label class="form-label">
          600x400 pixels in PNG file format recommended.
          Max file size 2MB.
        </label>
        <div class="my-3"><FormFieldError :fieldModel="selectedPortal.fields.orderIdHelpModalImage1" /></div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.orderIdHelpModalImage1"
          name="orderIdHelp1"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isOrderIdHelpDisabled"
          :maxSize="2000"
        />
      </div>
      <hr class="border border-dark my-5">
      <div class="form-group">
        <label class="form-label">Body Copy 2 (Optional)</label>
        <FormFieldTextarea
          class="form-control"
          placeholder="Add a second message"
          :disabled="isOrderIdHelpDisabled"
          :fieldModel="selectedPortal.fields.orderIdHelpModalMessageOptional1"
          rows="5"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.orderIdHelpModalMessageOptional1" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">Image 2 (Optional)</label>
        <label class="form-label">
          600x400 pixels in PNG file format recommended.
          Max file size 2MB.
        </label>
        <div class="my-3"><FormFieldError :fieldModel="selectedPortal.fields.orderIdHelpModalImage2" /></div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.orderIdHelpModalImage2"
          name="orderIdHelp2"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isOrderIdHelpDisabled"
          :maxSize="2000"
        />
      </div>
      <hr class="border border-dark my-5">
      <div class="form-group">
        <label class="form-label">Body Copy 3 (Optional)</label>
        <FormFieldTextarea
          class="form-control"
          placeholder="Add a third message"
          :disabled="isOrderIdHelpDisabled"
          :fieldModel="selectedPortal.fields.orderIdHelpModalMessageOptional2"
          rows="5"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.orderIdHelpModalMessageOptional2" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">Image 3 (Optional)</label>
        <label class="form-label">
          600x400 pixels in PNG file format recommended.
          Max file size 2MB.
        </label>
        <div class="my-3"><FormFieldError :fieldModel="selectedPortal.fields.orderIdHelpModalImage3" /></div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.orderIdHelpModalImage3"
          name="orderIdHelp3"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isOrderIdHelpDisabled"
          :maxSize="2000"
        />
      </div>
    </div>
    <div class="col-8">
      <OrderIdHelpModalPreview />
    </div>
  </div>
</template>

