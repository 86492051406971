<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { getFileUrl } from '@/utils/file'
import ModalPreview from '@/components/customize/ModalPreview.vue'

const { selectedPortal } = storeToRefs(usePortalsStore())

const image1Url =  computed(() => {
  const image = selectedPortal.value.fields?.orderIdHelpModalImage1.inputValue
  if (image) {
    return getFileUrl(image)
  }
  return null
})
const image2Url =  computed(() => {
  const image = selectedPortal.value.fields?.orderIdHelpModalImage2.inputValue
  if (image) {
    return getFileUrl(image)
  }
  return null
})
const image3Url =  computed(() => {
  const image = selectedPortal.value.fields?.orderIdHelpModalImage3.inputValue
  if (image) {
    return getFileUrl(image)
  }
  return null
})
const OrderIdHelpModalMessage = computed(() => {
  return selectedPortal.value.fields.orderIdHelpModalMessage.inputValue
})
const OrderIdHelpModalMessageOptional1 = computed(() => {
  return selectedPortal.value.fields.orderIdHelpModalMessageOptional1.inputValue
})
const OrderIdHelpModalMessageOptional2 = computed(() => {
  return selectedPortal.value.fields.orderIdHelpModalMessageOptional2.inputValue
})
const OrderIdHelpModalHeader = computed(() => {
  return selectedPortal.value.fields.orderIdHelpModalHeader.inputValue
})
</script>

<template>
  <ModalPreview :header="OrderIdHelpModalHeader">
    <p>{{ OrderIdHelpModalMessage }}</p>
    <br>
    <div>
      <img
        class="order-id-help-img"
        v-if="image1Url"
        :src="image1Url"
        alt=""
      >
    </div>
    <br>
    <p>{{ OrderIdHelpModalMessageOptional1 }}</p>
    <div>
      <img
        class="order-id-help-img"
        v-if="image2Url"
        :src="image2Url"
        alt=""
      >
    </div>
    <br>
    <p>{{ OrderIdHelpModalMessageOptional2 }}</p>
    <div>
      <img
        class="order-id-help-img"
        v-if="image3Url"
        :src="image3Url"
        alt=""
      >
    </div>
  </ModalPreview>
</template>

<style lang="scss" scoped>
.order-id-help-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
}
</style>
