import { object, string, boolean } from 'yup'
import { URL_REGEX } from '@/constants'
import { getPortalUrl } from '@/utils/portal'

export default object({
  orderIdHelpEnabled: boolean(),
  customerSupportContact: string().label('Phone').notRequired(),
  giftReturnsAllowed: boolean(),
  urlPrefix: string()
    .label('Portal url prefix')
    .test(
        'is-valid',
        '${label} is not valid',
        portalUrlPrefix => {
            return URL_REGEX.test(getPortalUrl(portalUrlPrefix))
        })
    .required(),
  siteWideMessageEnabled: boolean(),
  importantMessage: string().label('Sitewide message')
    .when('siteWideMessageEnabled', {
        is: true,
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
    })
})
